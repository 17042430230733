import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import * as destinatarioTrasportoApi from "../../../api/bolle_fatture/destinatarioTrasportoApi";
import * as vettoreTrasportoApi from "../../../api/bolle_fatture/vettoreTrasportoApi";
import * as documentiDiTrasportoApi from "../../../api/bolle_fatture/documentiDiTrasportoApi";
import * as corrispettiviApi from "../../../api/bolle_fatture/corrispettiviApi";
import { Lavorazione } from "../lavorazioni/lavorazioniSlice";
import { Contatto } from "../anagrafiche/anagraficheSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import { toast } from "react-toastify";

export interface DestinazioneTrasporto {
	id: number;
	destinatario: number;
	ragione_sociale: string;
	indirizzo?: string;
	cap?: number;
	comune?: string;
	provincia?: string;
}

export interface DestinazioniTrasporto {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: DestinazioneTrasporto[];
	errorsStack?: ErrorsStack;
}

export interface VettoreTrasporto {
	id: number;
	ragione_sociale: string;
	indirizzo?: string;
	cap?: number;
	comune?: string;
	provincia?: string;
}

export interface VettoriTrasporto {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: VettoreTrasporto[];
	errorsStack?: ErrorsStack;
}

export enum CausaleTrasportoTypes {
	L = "Resa da c/lavorazione",
	V = "Vendita",
}

export enum AspettoEsterioreBeniTypes {
	A = "Cassette",
	B = "Cartoni",
	C = "Cassette&Cartoni",
	D = "Cassoni",
}

export interface DettaglioDDT {
	id?: number;
	ddt: number;
	lavorazione?: number;
	colli: number;
	quantita: number;
	storico_codice_tracciabilita: string;
	storico_articolo: string;
	storico_descrizione: string;
	storico_prezzo: number;
	errorsStack?: ErrorsStack;
}

export interface DocumentoDiTrasporto {
	id: number | null;
	progressivo: number | null;
	causale_trasporto: CausaleTrasportoTypes | string;
	aspetto_esteriore_beni: AspettoEsterioreBeniTypes | string;
	aspetto_esteriore_beni_desc?: string;
	data_documento: Date | string;
	data_ora_trasporto: Date | string;
	destinatario?: number;
	destinazione?: number;
	vettore?: number;
	pallet_ingresso?: number | "";
	pallet_uscita?: number | "";
	annotazioni?: string;
	righe_dettaglio: DettaglioDDT[];
	colli_consegna?: number;
	kg_consegna?: number;
	annotazione_automatica?: string;
	estremi_invio?: string;
	errorsStack: ErrorsStack;
}

export interface DocumentiDiTrasporto {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: DocumentoDiTrasporto[];
	errorsStack: ErrorsStack;
}
export interface LavorazioneNonCompletate extends Lavorazione {
	lotto_codice: string;
	lotto_data: Date | string;
	tipo_capo: string;
}

export interface LavorazioniNonCompletate {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: LavorazioneNonCompletate[];
	errorsStack: ErrorsStack;
}

export interface CostoLavorazione {
	lavorazione_id: number;
	codice_articolo_committente: string;
	descrizione: string;
	numero_cassette_prodotte: number;
	numero_cassette_residue: number;
	peso_cassette_prodotte: number;
	peso_cassette_residue: number;
	costo_lavorazione: number;
	rimorchio: boolean;
}
export interface CostoTrasporto {
	id: number | null;
	tipo_tariffa: string;
	fascia_trasporto: string;
	lotti_per_viaggio: number;
	codici_lotti_condivisi: string;
	tariffa_motrice: number;
	minimo_garantito_motrice: number;
	tariffa_autotreno: number;
	minimo_garantito_autotreno: number;
	costo_trasporto: number;
	rimorchio: boolean;
	km_ar: number;
	kilometri_ar: number;
	calcolo_peso: "P" | "A";
	peso_partenza: number;
	peso_arrivo: number;
}

export interface ListinoMacellazione {
	id: number;
	nome: string;
	descrizione: string;
	tipo_listino: string;
	tipo_listino_desc: string;
}
export interface ListiniMacellazione {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: ListinoMacellazione[];
	errorsStack: ErrorsStack;
}
export interface ListinoTrasportoVivo {
	id: number;
	nome: string;
	descrizione: string;
	tipo_listino: string;
	tipo_listino_desc: string;
}
export interface ListiniTrasportoVivo {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: ListinoTrasportoVivo[];
	errorsStack: ErrorsStack;
}
export interface Prezzo {
	prezzo: number;
	articolo: number;
	data_validta_da?: Date;
	data_validta_a?: Date;
}
export interface ListinoVenditaArticoli {
	id: number;
	nome: string;
	descrizione: string;
	prezzi: Prezzo[];
}
export interface ListiniVenditaArticoli {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: ListinoVenditaArticoli[];
	errorsStack: ErrorsStack;
}
export interface TariffaMacellazione {
	prezzo: number;
	calcolo_peso: string;
	tipo_capo: number;
	tipo_capo_desc: string;
	lavorazione_committente: number;
}
export interface CorrispettiviLotto {
	lotto_id: number;
	listino_macellazione: number;
	listino_trasporti_vivo: number;
	data_lavorazione: Date | string;
	tariffa_macellazione: TariffaMacellazione;
	costo_macellazione: number;
	peso_lotto_partenza: number;
	peso_lotto_arrivo: number;
	costo_trasporto: number;
	costi_di_lavorazione_list: CostoLavorazione[];
	costi_di_trasporto_vivo_list: CostoTrasporto[];
	errorsStack: ErrorsStack;
}

export interface CorrispettiviLotti {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: CorrispettiviLotto[];
	errorsStack: ErrorsStack;
}

export interface BolleFattureStrutturaState {
	destinazioniTrasporto: DestinazioniTrasporto;
	vettoriTrasporto: VettoriTrasporto;
	documentiDiTrasporto: DocumentiDiTrasporto;
	ddtCorrente_id: number | null;
	lavorazioniNonCompletate: LavorazioniNonCompletate;
	listiniMacellazione: ListiniMacellazione;
	listiniTrasportoVivo: ListiniTrasportoVivo;
	listiniVenditaArticoli: ListiniVenditaArticoli;
	corrispettiviLotti: CorrispettiviLotti;
	errorsStack: ErrorsStack;
}

const initialState: BolleFattureStrutturaState = {
	destinazioniTrasporto: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	vettoriTrasporto: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	documentiDiTrasporto: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK, saving: false },
	},
	lavorazioniNonCompletate: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK, saving: false },
	},
	listiniMacellazione: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK, saving: false },
	},
	listiniTrasportoVivo: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK, saving: false },
	},
	listiniVenditaArticoli: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK, saving: false },
	},
	corrispettiviLotti: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK, saving: false },
	},
	ddtCorrente_id: null,

	errorsStack: { status: ErrorStatusTypes.OK },
};

export const fetchDestinazioniTrasporto = createAsyncThunk(
	"destinatarioTrasporto/fetchDestinazioniTrasporto",
	async () => {
		return await destinatarioTrasportoApi.fetchDestinazioniTrasporto();
		//   return user;
	}
);

export const fetchVettoriTrasporto = createAsyncThunk(
	"vettoriTrasporto/fetchVettoriTrasporto",
	async () => {
		return await vettoreTrasportoApi.fetchVettoriTrasporto();
		//   return user;
	}
);

export const fetchDocumentiDiTrasporto = createAsyncThunk(
	"documentiDiTrasporto/fetchDocumentiDiTrasporto",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await documentiDiTrasportoApi.fetchDocumentiDiTrasporto(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const getDocumentoDiTrasporto = createAsyncThunk(
	"documentiDiTrasporto/getDocumentoDiTrasporto",
	async (documentoDiTrasportoId: number) => {
		return await documentiDiTrasportoApi.getDocumentoDiTrasporto(
			documentoDiTrasportoId
		);
	}
);

export const saveDocumentoDiTrasporto = createAsyncThunk(
	"documentiDiTrasporto/saveDocumentoDiTrasporto",

	async (
		parametri: {
			documentoDiTrasportoToSave: DocumentoDiTrasporto;
			azione?: string;
		},

		thunkApi
	) => {
		return await documentiDiTrasportoApi.saveDocumentoDiTrasporto(
			parametri.documentoDiTrasportoToSave,
			parametri.azione
		);
	}
);

export const deleteDocumentoDiTrasporto = createAsyncThunk(
	"documentiDiTrasporto/deleteDocumentoDiTrasporto",
	async (documentoDiTrasportoToDelete: DocumentoDiTrasporto, thunkApi) => {
		return await documentiDiTrasportoApi.deleteDocumentoDiTrasporto(
			documentoDiTrasportoToDelete
		);
	}
);

export const fetchLavorazioniNonCompletate = createAsyncThunk(
	"documentiDiTrasporto/fetchLavorazioniNonCompletate",
	async (committente_id: number) => {
		return await documentiDiTrasportoApi.fetchLavorazioniNonCompletate(
			committente_id
		);
	}
);

export const aggiungiLavorazioniDdt = createAsyncThunk(
	"documentiDiTrasporto/aggiungiLavorazioniDdt",
	async (
		parametri: { ddt: DocumentoDiTrasporto; lavorazioni: number[] },
		thunkApi
	) => {
		return await documentiDiTrasportoApi
			.aggiungiLavorazioniDdt(parametri.ddt, parametri.lavorazioni)
			.then((response) => {
				parametri.ddt.id &&
					thunkApi.dispatch(getDocumentoDiTrasporto(parametri.ddt.id));
				parametri.ddt.destinatario &&
					thunkApi.dispatch(
						fetchLavorazioniNonCompletate(parametri.ddt.destinatario)
					);
				return response;
			});
	}
);

export const aggiungiDettagliDdt = createAsyncThunk(
	"documentiDiTrasporto/aggiungiDettagliDdt",
	async (
		parametri: { ddt: DocumentoDiTrasporto; dettaglioDdt: DettaglioDDT },
		thunkApi
	) => {
		return await documentiDiTrasportoApi
			.aggiungiDettagliDdt(parametri.ddt, parametri.dettaglioDdt)
			.then((response) => {
				parametri.ddt.id &&
					thunkApi.dispatch(getDocumentoDiTrasporto(parametri.ddt.id));
				parametri.ddt.destinatario &&
					thunkApi.dispatch(
						fetchLavorazioniNonCompletate(parametri.ddt.destinatario)
					);
				return response;
			});
	}
);

export const deleteDettaglioDocumentoDiTrasporto = createAsyncThunk(
	"documentiDiTrasporto/deleteDettaglioDocumentoDiTrasporto",
	async (
		parametri: { ddt: DocumentoDiTrasporto; rddt_id: number },
		thunkApi
	) => {
		return await documentiDiTrasportoApi
			.deleteDettaglioDocumentoDiTrasporto(parametri.rddt_id)
			.then((response) => {
				parametri.ddt.id &&
					thunkApi.dispatch(getDocumentoDiTrasporto(parametri.ddt.id));
				parametri.ddt.destinatario &&
					thunkApi.dispatch(
						fetchLavorazioniNonCompletate(parametri.ddt.destinatario)
					);
				return response;
			});
	}
);

export const saveDettaglioDocumentoDiTrasporto = createAsyncThunk(
	"documentiDiTrasporto/saveDettaglioDocumentoDiTrasporto",
	async (
		parametri: { ddt: DocumentoDiTrasporto; dettaglioDdt: DettaglioDDT },
		thunkApi
	) => {
		return await documentiDiTrasportoApi
			.saveDettaglioDocumentoDiTrasporto(parametri.dettaglioDdt)
			.then((response) => {
				parametri.ddt.id &&
					thunkApi.dispatch(getDocumentoDiTrasporto(parametri.ddt.id));
				parametri.ddt.destinatario &&
					thunkApi.dispatch(
						fetchLavorazioniNonCompletate(parametri.ddt.destinatario)
					);
				return response;
			});
	}
);

export const mandaDdtEmail = createAsyncThunk(
	"documentiDiTrasporto/mandaDdtEmail",
	async (
		parametriMail: {
			ddt_id: number;
			file: File;
			contatto: Contatto;
		},
		thunkApi
	) => {
		return await documentiDiTrasportoApi.mandaDdtEmail(
			parametriMail.ddt_id,
			parametriMail.file,
			parametriMail.contatto.id
		);
	}
);
// ####################################################################################

export const fetchListiniMacellazione = createAsyncThunk(
	"corrispettivi/fetchListiniMacellazione",
	async () => {
		return await corrispettiviApi.fetchListiniMacellazione();
	}
);

export const fetchListiniTrasportoVivo = createAsyncThunk(
	"corrispettivi/fetchListiniTrasportoVivo",
	async () => {
		return await corrispettiviApi.fetchListiniTrasportoVivo();
	}
);

export const fetchListiniVenditaArticoli = createAsyncThunk(
	"corrispettivi/fetchListiniVenditaArticoli",
	async () => {
		return await corrispettiviApi.fetchListiniVenditaArticoli();
	}
);

export const getCorrispettiviLotto = createAsyncThunk(
	"corrispettivi/getCorrispettiviLotto",
	async (lottoId: number) => {
		return await corrispettiviApi.getCorrispettiviLotto(lottoId);
	}
);

export const modificaCorrispettiviLotto = createAsyncThunk(
	"corrispettivi/modificaCorrispettiviLotto",
	async (corrispettiviLotto: CorrispettiviLotto) => {
		return await corrispettiviApi.modificaCorrispettiviLotto(
			corrispettiviLotto
		);
	}
);

// ####################################################################################

export const bolleFattureSlice = createSlice({
	name: "bolle_fatture",
	initialState,
	reducers: {
		setDdtCorrente: (
			state,
			action: PayloadAction<{
				ddtCorrente_id: number | null;
			}>
		) => {
			state.ddtCorrente_id = action.payload.ddtCorrente_id;
		},
		resetDocumentiDiTrasporto: (state) => {
			// state.lottoCorrente = initialState.lottoCorrente;
			state.documentiDiTrasporto = {
				count: 0,
				page: 0,
				num_pages: 0,
				next: undefined,
				previous: undefined,
				results: [],
				errorsStack: { status: ErrorStatusTypes.OK, saving: false },
			};
		},
		resetLavorazioniNonCompletate: (state) => {
			// state.lottoCorrente = initialState.lottoCorrente;
			state.lavorazioniNonCompletate = {
				count: 0,
				page: 0,
				num_pages: 0,
				next: undefined,
				previous: undefined,
				results: [],
				errorsStack: { status: ErrorStatusTypes.OK, saving: false },
			};
		},
	},
	extraReducers: (builder) => {
		// fetch Destinatari Trasporto
		builder.addCase(fetchDestinazioniTrasporto.pending, (state, action) => {});
		builder.addCase(fetchDestinazioniTrasporto.fulfilled, (state, action) => {
			state.destinazioniTrasporto = action.payload;
		});
		builder.addCase(fetchDestinazioniTrasporto.rejected, (state, action) => {});

		// fetch Vettori Trasporto
		builder.addCase(fetchVettoriTrasporto.pending, (state, action) => {});
		builder.addCase(fetchVettoriTrasporto.fulfilled, (state, action) => {
			state.vettoriTrasporto = action.payload;
		});
		builder.addCase(fetchVettoriTrasporto.rejected, (state, action) => {});

		// fetch Documenti Di Trasporto
		builder.addCase(fetchDocumentiDiTrasporto.pending, (state, action) => {
			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(fetchDocumentiDiTrasporto.fulfilled, (state, action) => {
			// state.documentiDiTrasporto = action.payload;

			state.documentiDiTrasporto = action.payload;
			// state.documentiDiTrasporto.results = [
			// 	...action.payload.results,
			// 	...state.documentiDiTrasporto.results.filter((documentoDiTrasporto) => {
			// 		if (
			// 			action.payload.results.find(
			// 				(documentoDiTrasportoNew: DocumentoDiTrasporto) =>
			// 					documentoDiTrasportoNew.id == documentoDiTrasporto.id
			// 			)
			// 		) {
			// 			return false;
			// 		} else {
			// 			return true;
			// 		}
			// 	}),
			// ];

			state.documentiDiTrasporto.errorsStack = {
				...state.documentiDiTrasporto.errorsStack,
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchDocumentiDiTrasporto.rejected, (state, action) => {
			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.ERROR;
		});

		// get documentoDiTrasporto
		builder.addCase(getDocumentoDiTrasporto.pending, (state, action) => {
			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(getDocumentoDiTrasporto.fulfilled, (state, action) => {
			// state.documentoDiTrasportoCorrente = action.payload;

			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.OK;
			let prima_assente: boolean = true;
			state.documentiDiTrasporto.results =
				state.documentiDiTrasporto.results.map((documentoDiTrasporto) => {
					if (documentoDiTrasporto.id == action.payload.id) {
						// return action.payload;
						prima_assente = false;
						return {
							...action.payload,
							data_documento: moment(action.payload.data_documento).format(
								"YYYY-MM-DD"
							),
							data_ora_trasporto: moment(
								action.payload.data_ora_trasporto
							).toISOString(),
							errorsStack: {
								status: ErrorStatusTypes.OK,
								fieldsErrors: undefined,
							},
						};
					} else {
						return documentoDiTrasporto;
					}
				});
			if (prima_assente) {
				state.documentiDiTrasporto.results = [
					...state.documentiDiTrasporto.results,
					action.payload,
				];
			}
		});
		builder.addCase(getDocumentoDiTrasporto.rejected, (state, action) => {
			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.ERROR;
		});

		// save documentoDiTrasporto
		builder.addCase(saveDocumentoDiTrasporto.pending, (state, action) => {
			state.documentiDiTrasporto.errorsStack = {
				status: ErrorStatusTypes.PENDING,
				saving: true,
			};
		});

		builder.addCase(saveDocumentoDiTrasporto.fulfilled, (state, action) => {
			state.documentiDiTrasporto.results =
				state.documentiDiTrasporto.results.filter(
					(documentoDiTrasporto) => documentoDiTrasporto.id != action.payload.id
				);
			state.documentiDiTrasporto.results.push({
				...action.payload,
				errorsStack: { status: ErrorStatusTypes.SUCCESS },
			});
			state.ddtCorrente_id = action.payload.id;
			toast.success("DocumentoDiTrasporto salvato.");

			state.documentiDiTrasporto.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
				saving: false,
			};
		});

		builder.addCase(saveDocumentoDiTrasporto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.documentiDiTrasporto.results =
				state.documentiDiTrasporto.results.map((documentoDiTrasporto) => {
					if (
						documentoDiTrasporto.id ==
						action.meta.arg.documentoDiTrasportoToSave.id
					) {
						const errorMessage = JSON.parse(
							(action?.error?.message || "").replace(/^Error: /, "")
						);
						return {
							...documentoDiTrasporto,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: errorMessage,
							},
						};
					} else {
						return documentoDiTrasporto;
					}
				});
			state.documentiDiTrasporto.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});

		// delete documentoDiTrasportoss
		builder.addCase(deleteDocumentoDiTrasporto.pending, (state, action) => {
			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.PENDING;
		});

		builder.addCase(deleteDocumentoDiTrasporto.fulfilled, (state, action) => {
			state.documentiDiTrasporto.results =
				state.documentiDiTrasporto.results.filter(
					(documentoDiTrasporto) =>
						documentoDiTrasporto.id != action.payload.documentoDiTrasporto_id
				);
			toast.success("DocumentoDiTrasporto cancellato.");

			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.OK;
		});

		builder.addCase(deleteDocumentoDiTrasporto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.ERROR;
			state.documentiDiTrasporto.errorsStack.fieldsErrors = JSON.parse(
				action?.error?.message || ""
			);
		});

		// fetch Destinatari Trasporto
		builder.addCase(fetchLavorazioniNonCompletate.pending, (state, action) => {
			state.lavorazioniNonCompletate.errorsStack.status =
				ErrorStatusTypes.PENDING;
		});
		builder.addCase(
			fetchLavorazioniNonCompletate.fulfilled,
			(state, action) => {
				state.lavorazioniNonCompletate = action.payload;
				state.lavorazioniNonCompletate.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(fetchLavorazioniNonCompletate.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.lavorazioniNonCompletate.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// put lavorazione per creare dettaglio su ddt
		builder.addCase(aggiungiLavorazioniDdt.pending, (state, action) => {
			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(aggiungiLavorazioniDdt.fulfilled, (state, action) => {
			// state.lavorazioniNonCompletate = action.payload;
			state.documentiDiTrasporto.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(aggiungiLavorazioniDdt.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.documentiDiTrasporto.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// put  dettaglio su ddt
		builder.addCase(aggiungiDettagliDdt.pending, (state, action) => {
			state.documentiDiTrasporto.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(aggiungiDettagliDdt.fulfilled, (state, action) => {
			// state.lavorazioniNonCompletate = action.payload;
			state.documentiDiTrasporto.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(aggiungiDettagliDdt.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.documentiDiTrasporto.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});

		// cancella riga di dettaglio ddt
		builder.addCase(
			deleteDettaglioDocumentoDiTrasporto.pending,
			(state, action) => {
				state.documentiDiTrasporto.errorsStack.status =
					ErrorStatusTypes.PENDING;
			}
		);
		builder.addCase(
			deleteDettaglioDocumentoDiTrasporto.fulfilled,
			(state, action) => {
				// state.lavorazioniNonCompletate = action.payload;
				state.documentiDiTrasporto.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(
			deleteDettaglioDocumentoDiTrasporto.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");

				state.documentiDiTrasporto.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);

		// salva riga di dettaglio ddt
		builder.addCase(
			saveDettaglioDocumentoDiTrasporto.pending,
			(state, action) => {
				state.documentiDiTrasporto.errorsStack.status =
					ErrorStatusTypes.PENDING;
			}
		);
		builder.addCase(
			saveDettaglioDocumentoDiTrasporto.fulfilled,
			(state, action) => {
				// TODO verificare necessità di aggiornamento redux
				// state.lavorazioniNonCompletate = action.payload;
				state.documentiDiTrasporto.errorsStack = {
					status: ErrorStatusTypes.OK,
				};
			}
		);
		builder.addCase(
			saveDettaglioDocumentoDiTrasporto.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");

				state.documentiDiTrasporto.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);

		// carica Documento
		builder.addCase(mandaDdtEmail.pending, (state, action) => {
			state.documentiDiTrasporto.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(mandaDdtEmail.fulfilled, (state, action) => {
			state.documentiDiTrasporto.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Resa inviata.");
		});
		builder.addCase(mandaDdtEmail.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.documentiDiTrasporto.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// ################ Corrispettivi ######################################

		// fetch Listini Macellazione e Trasporto Vivo
		builder.addCase(fetchListiniMacellazione.pending, (state, action) => {});
		builder.addCase(fetchListiniMacellazione.fulfilled, (state, action) => {
			state.listiniMacellazione = action.payload;
		});
		builder.addCase(fetchListiniMacellazione.rejected, (state, action) => {});
		// *
		builder.addCase(fetchListiniTrasportoVivo.pending, (state, action) => {});
		builder.addCase(fetchListiniTrasportoVivo.fulfilled, (state, action) => {
			state.listiniTrasportoVivo = action.payload;
		});
		builder.addCase(fetchListiniTrasportoVivo.rejected, (state, action) => {});

		builder.addCase(fetchListiniVenditaArticoli.pending, (state, action) => {});
		builder.addCase(fetchListiniVenditaArticoli.fulfilled, (state, action) => {
			state.listiniVenditaArticoli = action.payload;
		});
		builder.addCase(
			fetchListiniVenditaArticoli.rejected,
			(state, action) => {}
		);

		// get Corrispettivi Lotto
		builder.addCase(getCorrispettiviLotto.pending, (state, action) => {
			state.corrispettiviLotti.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(getCorrispettiviLotto.fulfilled, (state, action) => {
			// state.documentoDiTrasportoCorrente = action.payload;

			state.corrispettiviLotti.errorsStack.status = ErrorStatusTypes.OK;
			let prima_assente: boolean = true;
			state.corrispettiviLotti.results = state.corrispettiviLotti.results.map(
				(corrispettiviLotto) => {
					if (corrispettiviLotto.lotto_id == action.payload.lotto_id) {
						// return action.payload;
						prima_assente = false;
						return {
							...action.payload,
							data_lavorazione: moment(action.payload.data_lavorazione).format(
								"YYYY-MM-DD"
							),
							errorsStack: {
								status: ErrorStatusTypes.OK,
								fieldsErrors: undefined,
							},
						};
					} else {
						return corrispettiviLotto;
					}
				}
			);
			if (prima_assente) {
				state.corrispettiviLotti.results = [
					...state.corrispettiviLotti.results,
					action.payload,
				];
			}
		});
		builder.addCase(getCorrispettiviLotto.rejected, (state, action) => {
			state.corrispettiviLotti.errorsStack.status = ErrorStatusTypes.ERROR;
		});

		// save modificaCorrispettiviLotto
		builder.addCase(modificaCorrispettiviLotto.pending, (state, action) => {
			state.corrispettiviLotti.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(modificaCorrispettiviLotto.fulfilled, (state, action) => {
			state.corrispettiviLotti.results = state.corrispettiviLotti.results.map(
				(corrispettiviLotto) => {
					if (corrispettiviLotto.lotto_id == action.payload.lotto_id) {
						return action.payload;
					} else {
						return corrispettiviLotto;
					}
				}
			);
			state.corrispettiviLotti.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			toast.success("Listino modificato.");
		});
		builder.addCase(modificaCorrispettiviLotto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "", {
				autoClose: false,
			});

			state.corrispettiviLotti.results = state.corrispettiviLotti.results.map(
				(corrispettiviLotto) => {
					if (corrispettiviLotto.lotto_id == action.meta.arg.lotto_id) {
						return {
							...corrispettiviLotto,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return corrispettiviLotto;
					}
				}
			);
			state.corrispettiviLotti.errorsStack = {
				status: ErrorStatusTypes.ERROR,
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {
	setDdtCorrente,
	resetDocumentiDiTrasporto,
	resetLavorazioniNonCompletate,
} = bolleFattureSlice.actions;

export const bolleFattureSliceReducer = bolleFattureSlice.reducer;

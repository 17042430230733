import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { Routes_path } from "../../../routerApp";
import { Committente } from "../../anagrafiche/anagraficheSlice";
import { UtentiEsterni } from "../ospitiSlice";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import PaperMui from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

interface UtentiEsterniListProps {
	utentiEsterni: UtentiEsterni;
	committenti: Committente[];
}

const UtentiEsterniList = ({
	utentiEsterni,
	committenti,
}: UtentiEsterniListProps) => {
	return (
		<Grid container justifyContent="center">
			<Grid item xs={12} md={11} lg={10}>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 700 }} aria-label="customized table">
						<TableHead>
							<TableRow>
								<StyledTableCell>n*</StyledTableCell>
								<StyledTableCell>Utente</StyledTableCell>
								<StyledTableCell>Nome</StyledTableCell>
								<StyledTableCell>Cognome</StyledTableCell>
								<StyledTableCell>Email</StyledTableCell>
								<StyledTableCell>Ultimo acesso</StyledTableCell>
								<StyledTableCell>Accesso a...</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{utentiEsterni.results.map((utente) => (
								<StyledTableRow key={utente.id}>
									<StyledTableCell component="th" scope="row">
										<Link
											to={{
												pathname: Routes_path.UTENTE_ESTERNO,
											}}
											state={{
												utente_id: utente.id,
											}}
										>
											{utente.id}
										</Link>
									</StyledTableCell>
									<StyledTableCell component="th" scope="row">
										{utente.user?.username}
									</StyledTableCell>
									<StyledTableCell>{utente.user?.first_name}</StyledTableCell>
									<StyledTableCell>{utente.user?.last_name}</StyledTableCell>
									<StyledTableCell>{utente.user?.email}</StyledTableCell>
									<StyledTableCell>
										{moment(utente.user?.last_login).format(
											"ddd DD yyyy hh:mm"
										)}
									</StyledTableCell>
									<StyledTableCell>
										{utente.committenti
											.map((committente_id) => {
												return committenti.find(
													(committente) => committente.id == committente_id
												)?.nome;
											})
											.join(", ")}
									</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

export default UtentiEsterniList;

import React from "react";

import RouterApp, { Routes_path } from "./routerApp";
// import { useLocation } from "react-router-dom";

import SideBar from "./common/sideBar/SideBar";
import TopBar from "./common/topBar/TopBar";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../redux/store";
// import { UtenteLogged } from "./authentication/authenticationSlice";
import {
	PreferenzeUtente,
	setDrawerAperto,
} from "./userPreference/userPreferenceSlice";
import CssBaseline from "@mui/material/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Copyright from "./common/Copyright";
import { Stack } from "@mui/material";

import logoPiccolo from "../../static/images/logo/logo-Bow_bianco.png";
import logoGrande from "../../static/images/logo/logo-Bow_trasparente.png";

import {
	useTheme,
	ThemeProvider,
	styled,
	Theme,
	CSSObject,
	createTheme,
} from "@mui/material/styles";

const sideMenuDrawerWidth = 240;
const miniSideMenuDrawerWidth = 70;
const appBarAltezza = 80;

const HeaderSpacer = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: 0,
	marginTop: appBarAltezza,
	// // necessary for content to be below app bar
	// ...theme.mixins.toolbar,
}));
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(9)} + 1px)`,
	},
});

const AppComposer = () => {
	// const location = useLocation();
	const dispatch = useAppDispatch();
	// const utente = useSelector<RootState, UtenteLogged>(
	// 	(state) => state.authentication.utenteLogged
	// );
	const preferenzeUtente = useSelector<RootState, PreferenzeUtente>(
		(state) => state.userPreferenceState.preferenzeUtente
	);

	// const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
	const [drawerPreview, setDrawerPreview] = React.useState(false);

	const [drawerIsOpen, setDrawerIsOpen] = React.useState(
		preferenzeUtente.drawer_aperto
		// true
	);

	const tooggleDrawer = () => {
		setDrawerIsOpen(!drawerIsOpen);
		dispatch(setDrawerAperto(!drawerIsOpen));
	};

	const drawerPreviewOn = () => {
		setDrawerPreview(true);
	};

	const drawerPreviewOff = () => {
		setDrawerPreview(false);
	};

	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />

			{/* <TopBar
				open={drawerIsOpen || drawerPreview}
				sideMenuDrawerWidth={0}
				miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
				appBarAltezza={appBarAltezza}
				logoPiccolo={logoPiccolo}
				tooggleDrawer={tooggleDrawer}
			/> */}

			<TopBar
				open={open}
				drawerWidth={drawerWidth}
				handleDrawerOpen={handleDrawerOpen}
			/>
			{/* {location.pathname != Routes_path.HOME && ( */}
			{/* <SideBar
				drawerPreviewOn={drawerPreviewOn}
				drawerPreviewOff={drawerPreviewOff}
				open={drawerIsOpen || drawerPreview}
				sideMenuDrawerWidth={sideMenuDrawerWidth}
				miniSideMenuDrawerWidth={miniSideMenuDrawerWidth}
				appBarAltezza={appBarAltezza}
				tooggleDrawer={tooggleDrawer}
				logoPiccolo={logoPiccolo}
				logoGrande={logoGrande}
			/> */}

			<SideBar
				open={open}
				drawerWidth={drawerWidth}
				handleDrawerClose={handleDrawerClose}
				openedMixin={openedMixin}
				closedMixin={closedMixin}
			/>
			<Box component="main" sx={{ flexGrow: 1, p: 0 }}>
				<HeaderSpacer />
				<Box sx={{ minHeight: "50vh", p: 3, m: 0 }}>
					<RouterApp />
					<Copyright />
				</Box>
			</Box>
		</Box>
	);
};

export default AppComposer;

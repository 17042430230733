import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import AllegatiUploadForm from "./AllegatiUploadForm";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	Allegato,
	TipoDocumento,
	AllegatiState,
	uploadAllegato,
	fetchAllegati,
	fetchTipiDocumento,
	deleteAllegato,
} from "./allegatiSlice";
import AlleggatiList from "./AlleggatiList";

import Box from "@mui/material/Box";
import { Typography, Link } from "@mui/material";

interface AlleggatiPageProps {
	schedaCarico_id?: number | null;
	eventoManutenzione_id?: number | null;
	// annulla?: () => void;
}

const AlleggatiPage = ({
	schedaCarico_id,
	eventoManutenzione_id,
	// annulla,
	...props
}: AlleggatiPageProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchTipiDocumento());
		dispatch(fetchAllegati({ schedaCarico_id, eventoManutenzione_id }));
	}, []);

	const tipiDocumento: TipoDocumento[] = useSelector<
		RootState,
		TipoDocumento[]
	>((state) => state.allegati.tipiDocumento.results);

	const allegati: Allegato[] | undefined = useSelector<
		RootState,
		Allegato[] | undefined
	>((state) =>
		state.allegati.allegati.results.filter(
			(allegato) =>
				(schedaCarico_id && allegato.scheda_carico == schedaCarico_id) ||
				(eventoManutenzione_id &&
					allegato.evento_manutenzione == eventoManutenzione_id)
		)
	);

	const allegatoCorrente: AllegatiState | undefined = useSelector<
		RootState,
		AllegatiState | undefined
	>((state) => state.allegati.allegatoCorrente);

	// const [poiChiudi, setPoiChiudi] = useState(false);

	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const handleUploadAllegato = (
		allegato: Allegato,
		selectedFile: File | null
	) => {
		selectedFile &&
			dispatch(
				uploadAllegato({
					allegato: allegato,
					file: selectedFile,
				})
			);
	};

	const handleDelete = (allegato: Allegato) => {
		dispatch(deleteAllegato(allegato));
	};

	return (
		<>
			<Typography
				component="h3"
				variant="h5"
				sx={{
					color: "#094e8a",
					margin: 2,
					marginBottom: 0,
					padding: 2,
					paddingLeft: 0,
					paddingBottom: 0,
				}}
			>
				Allegati
			</Typography>
			<Box
				sx={{
					border: 1,
					borderRadius: 0.4,
					borderColor: "#094e8a",
					margin: 2,
					marginTop: 0,

					padding: 2,
					paddingTop: 0,
				}}
			>
				{allegati && (
					<AlleggatiList allegati={allegati} handleDelete={handleDelete} />
				)}

				<AllegatiUploadForm
					key={`AllegatiUploadForm_${eventoManutenzione_id}_${schedaCarico_id}`}
					allegato={{
						id: null,
						tipo_documento: schedaCarico_id ? 2 : 1,
						descrizione: "",
						scheda_carico: schedaCarico_id,
						evento_manutenzione: eventoManutenzione_id,
						autore: "",
						file: "",
					}}
					tipiDocumento={tipiDocumento}
					uploadAllegato={handleUploadAllegato}
					uploading={
						allegatoCorrente?.errorsStack.status != ErrorStatusTypes.OK
					}
				/>
			</Box>
		</>
	);
};

export default AlleggatiPage;

import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Utente } from "../../authentication/authenticationSlice";

import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Routes_path } from "../../routerApp";
import { PermissionTypes } from "../../authentication/permissionsGroups";
import HomeMenuButton from "./HomeMenuButton";
import List from "@mui/material/List";
import TableChartIcon from "@mui/icons-material/TableChart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
// import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import DomainIcon from "@mui/icons-material/Domain";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import Grid from "@mui/material/Grid";

const PREFIX = "HomeMenu";

const classes = {
	iconSelected: `${PREFIX}-iconSelected`,
};

const StyledList = styled(List)(() => ({
	[`& .${classes.iconSelected}`]: {
		color: "#1b75b1",
	},
}));

const styleListItem = { justifyContent: "center" };

interface HomeMenuProps {
	// open: boolean;
	// handleDrawerClose: () => void;
}
const HomeMenu = () => {
	const location = useLocation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	// const matches = useMediaQuery("min-width:900px)");
	const mobileHandleDrawerClose = () => {
		// matches && handleDrawerClose();
	};

	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			// sx={{ flexGrow: 1, mb: 1 }}
			rowSpacing={2}
			columnSpacing={{ xs: 1, sm: 2, md: 3 }}
			sx={{ my: 2 }}
		>
			<HomeMenuButton
				nome="Pianificazione"
				utente={utente}
				gruppiAutorizzati={[
					PermissionTypes.PIANIFICATORI,
					PermissionTypes.PIANIFICATORI_LETTURA,
				]}
				path={Routes_path.PIANIFICAZIONE}
				Icon={TableChartIcon}
			/>
			<HomeMenuButton
				nome="Schede Carico"
				utente={utente}
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				path={Routes_path.SCHEDE_CARICO}
				Icon={LocalShippingIcon}
			/>
			<HomeMenuButton
				nome="Piani di Viaggio"
				utente={utente}
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
					PermissionTypes.AUTISTI,
				]}
				path={Routes_path.PIANI_VIAGGIO}
				Icon={ShareLocationIcon}
			/>
			<HomeMenuButton
				nome="Piano Lavorazioni"
				utente={utente}
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_MACELLO,
					PermissionTypes.SCHEDULATORI_LAVORAZIONI,
					PermissionTypes.SCHEDULATORI_ESTERNI,
				]}
				path={Routes_path.PIANO_LAVORAZIONI}
				Icon={FormatListNumberedIcon}
			/>
			<HomeMenuButton
				nome="Consuntivi Vivo"
				utente={utente}
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				path={Routes_path.CONSUNTIVO_VIVO}
				Icon={AppRegistrationIcon}
			/>
			<HomeMenuButton
				nome="Schede Macello"
				utente={utente}
				gruppiAutorizzati={[PermissionTypes.OPERATORI_MACELLO]}
				path={Routes_path.SCHEDA_MACELLO}
				Icon={ContentPasteIcon}
			/>
			<HomeMenuButton
				nome="Consuntivo Lavorazioni"
				utente={utente}
				gruppiAutorizzati={[PermissionTypes.OPERATORI_MACELLO]}
				path={Routes_path.CONSUNTIVO_LAVORAZIONI}
				Icon={AllInboxIcon}
			/>
			<HomeMenuButton
				nome="Report Veterinario"
				utente={utente}
				gruppiAutorizzati={[PermissionTypes.VETERINARI]}
				path={Routes_path.REPORT_VETERINARIO}
				Icon={MedicalServicesIcon}
			/>
			<HomeMenuButton
				nome="Documenti di Trasporto"
				utente={utente}
				gruppiAutorizzati={[
					PermissionTypes.OPERATORI_LOGISTICA,
					PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
				]}
				path={Routes_path.DOCUMENTI_TRASPORTO}
				Icon={WarehouseIcon}
			/>
			<HomeMenuButton
				nome="Dashboard Committente"
				utente={utente}
				gruppiAutorizzati={[PermissionTypes.OSPITI]}
				path={Routes_path.DASHBOARD_COMMITTENTI}
				Icon={DomainIcon}
			/>
			<HomeMenuButton
				nome="Lotti Committente"
				utente={utente}
				gruppiAutorizzati={[PermissionTypes.OSPITI]}
				path={Routes_path.LOTTI_COMMITTENTE}
				Icon={ListAltIcon}
			/>
			<HomeMenuButton
				nome="Allevamenti Committente"
				utente={utente}
				gruppiAutorizzati={[PermissionTypes.OSPITI]}
				path={Routes_path.ALLEVAMENTI_COMMITTENTE}
				Icon={AgricultureIcon}
			/>
		</Grid>
	);
};

export default HomeMenu;

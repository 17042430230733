import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { Link, useLocation } from "react-router-dom";

import { fetchLottiCommittente, LottiCommittente } from "../ospitiSlice";

import { Committente, Committenti } from "../../anagrafiche/anagraficheSlice";
import { SelectCommittente } from "../dashboard/DashboardCommittentiToolbarOptions";

import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";

import moment from "moment";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DateToday from "../../../common/dateUtils/DateToday";
import PaperMui from "@mui/material/Paper";
import AllevamentiCommittenteBox from "./AllevamentiCommittenteBox";

import { styled, Box } from "@mui/system";

const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

const AllevamentiCommittentePage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const committenti: Committenti = useSelector<RootState, Committenti>(
		(state) => state.anagrafiche.committenti
	);

	const [committenteCorrente, setCommittenteCorrente] =
		React.useState<Committente | null>(null);

	useEffect(() => {
		!committenteCorrente && setCommittenteCorrente(committenti.results[0]);
	}, [committenti.results]);

	const location = useLocation();

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		committenteCorrente?.id &&
			// dispatch(
			// 	fetchLottiCommittente({ committente_id: committenteCorrente?.id })
			// );
			setRefresh(false);
	}, [committenteCorrente, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					committenti.errorsStack.status == ErrorStatusTypes.PENDING
					// schedeCarico.errorsStack.status == ErrorStatusTypes.PENDING ||
					// consuntiviVivo.errorsStack.status == ErrorStatusTypes.PENDING ||
					// anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Lotti " + committenteCorrente?.nome}
				dataPresente={false}
				setData={(data: Date) => {}}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							sx={{ ml: 2 }}
							spacing={1}
						>
							{committenti.results && (
								<SelectCommittente
									committenteCorrente={committenteCorrente}
									committenti={committenti.results}
									setCommittenteCorrente={setCommittenteCorrente}
								/>
							)}
						</Stack>
					);
				}}
			/>
			{committenteCorrente && (
				<AllevamentiCommittenteBox
					key={"allevamentiCommittente_" + refresh}
					committenteCorrente={committenteCorrente}
				/>
			)}
		</Box>
	);
};

export default AllevamentiCommittentePage;

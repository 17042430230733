import React from "react";
import { Link } from "react-router-dom";

import { Committente } from "../../anagrafiche/anagraficheSlice";
import { Routes_path } from "../../../routerApp";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PaperMui from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import { styled, Box } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

interface CommittentiListProps {
	committenti: Committente[];
}

const CommittentiList = ({ committenti }: CommittentiListProps) => {
	return (
		<Grid container justifyContent="center">
			<Grid item xs={12} md={9} lg={7}>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 700 }} aria-label="customized table">
						<TableHead>
							<TableRow>
								<StyledTableCell>Committente</StyledTableCell>
								<StyledTableCell align="right">Utenti Esterni</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{committenti.map((committente) => (
								<StyledTableRow key={committente.id}>
									<StyledTableCell component="th" scope="row">
										{committente.nome}
									</StyledTableCell>
									<StyledTableCell align="right">
										{committente.utenti_esterni.map((utente) => {
											const nomeUtente = (
												<Box
													component={Link}
													to={{
														pathname: Routes_path.UTENTE_ESTERNO,
													}}
													state={{
														utente_id: utente.id,
													}}
													sx={{ mr: 1 }}
													key={utente.id}
												>
													{utente.user?.username + " "}
												</Box>
											);
											return nomeUtente;
										})}
									</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

export default CommittentiList;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	Lotto,
	getLotto,
	PianificazioneStrutturaState,
} from "../pianificazione/pianificazioneSlice";

import { SchedaCarico } from "../schedeCarico/schedeCaricoSlice";
import { ConsuntivoVivo } from "../schedeCarico/consuntiviVivo/consuntiviVivoSlice";

import {
	Camion,
	Autista,
	fetchCamions,
	fetchAutisti,
} from "../anagrafiche/anagraficheSlice";

import * as reportAutistiCamionApi from "../../../api/reportistica/reportisticaApi";
import moment from "moment";
import { toast } from "react-toastify";
import { camionNecessari } from "../pianificazione/utility/lottoUtility";

moment.locale("it");
// export interface StatoConsuntivoVivo {
// 	stato: number;
// 	descrizione: string;
// 	messaggio: string;
// }

export interface StatoConsuntivoVivo {
	stato: number;
	descrizione: string;
	messaggio: string;
}

type ConsuntivoVivoRidotto = Omit<
	ConsuntivoVivo,
	| "numero_ordine_giorno"
	| "ordinamento_scheda_carico"
	| "numero_capi_pianificati"
	| "lotto"
	| "numero_fori"
	| "numero_capi_foro"
	| "rimorchio"
	| "data_ora_carico_pianificata"
	| "allegati"
	| "numero_capi_ritirati"
	| "data_ora_inizio_carico"
	| "data_ora_fine_carico"
	| "data_ora_arrivo_pianificata"
	| "data_ora_arrivo_effettiva"
	| "peso_medio_dichiarato"
	| "peso_medio_arrivo"
	| "calo_concordato"
	| "tara_in_carico_motrice"
	| "tara_in_carico_rimorchio"
	| "lordo_in_carico_motrice"
	| "lordo_in_carico_rimorchio"
	| "lordo_in_arrivo_motrice"
	| "lordo_in_arrivo_rimorchio"
	| "tara_in_arrivo_motrice"
	| "tara_in_arrivo_rimorchio"
	| "numero_gabbioni_motrice"
	| "numero_gabbioni_rimorchio"
	| "peso_partenza"
	| "peso_arrivo"
	| "calo"
	| "tara_gabbie"
	| "note"
>;

export interface ReportAutistaCamion extends ConsuntivoVivoRidotto {
	id: number | null;
	camion?: number | null;
	camion_pack?: Camion;
	km_ar: number | null;
	autisti: number[];
	autisti_pack: Autista[];
	data_ora_partenza_sede?: Date | string;
	data_ora_fine_turno?: Date | string;
	delta_turno_per_autista?: number | string;
	stato_consuntivo_vivo?: StatoConsuntivoVivo;
	note?: string;
}
export interface ReportAutistiCamionState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: ReportAutistaCamion[];
	errorsStack: ErrorsStack;
}

export interface ReportAutisti {
	id: number;
	autista_id: number;
	cognome: string;
	nome: string;
	data_ora_partenza_sede?: Date | string;
	data_ora_fine_turno?: Date | string;
	delta_turno_per_autista?: number | string;
}

export interface ReportAutistiIolepec {
	id: number;
	cognome: string;
	nome: string;
	ore_totali: number;
	costo_orario: number;
	costo_totale: number;
}
export interface ReportCamionsIolepec {
	id: number;
	nome: string;
	targa: string;
	km_totali: number;
	costo_km: number;
	costo_totale: number;
}

export interface ListaBIViaggiAutistiCamionMese {
	id_lotto: number;
	id_scheda_viaggio: number;
	data_lavorazione: string;
	id_camion: number;
	targa: string;
	autista_id: number;
	autista: string;
	km_ar: number;
	ore_di_viaggio: number;
	ore_di_lavoro: number;
	kg_vivo_partenza: number;
	committente: string;
	tipo_capo: string;
	lotti_per_viaggio: number;
}

export interface ReportAutistaCamionStrutturaState {
	reportAutistiCamion: ReportAutistiCamionState;
	reportAutisti: ReportAutisti[];
	reportAutistiIolepec: ReportAutistiIolepec[];
	reportCamionsIolepec: ReportCamionsIolepec[];
	listaBIViaggiAutistiCamionMese: ListaBIViaggiAutistiCamionMese[];
}

const initialState: ReportAutistaCamionStrutturaState = {
	reportAutistiCamion: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	reportAutisti: [],
	reportAutistiIolepec: [],
	reportCamionsIolepec: [],
	listaBIViaggiAutistiCamionMese: [],
};

export const fetchReportAutistiCamion = createAsyncThunk(
	"report_autisti/fetchReportAutistiCamion",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await reportAutistiCamionApi.fetchReportAutistiCamion(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const fetchReportAutisti = createAsyncThunk(
	"report_autisti/fetchReportAutisti",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await reportAutistiCamionApi.fetchReportAutisti(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const fetchReportAutistiIolepec = createAsyncThunk(
	"report_autisti/fetchReportAutistiIolepec",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await reportAutistiCamionApi.fetchReportAutistiIolepec(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const fetchListaBIViaggiAutistiCamionMese = createAsyncThunk(
	"report_autisti/fetchListaBIViaggiAutistiCamionMese",
	async (data: Date) => {
		return await reportAutistiCamionApi.fetchListaBIViaggiAutistiCamionMese(
			data
		);
	}
);

export const fetchReportCamionsIolepec = createAsyncThunk(
	"report_autisti/fetchReportCamionsIolepec",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await reportAutistiCamionApi.fetchReportCamionsIolepec(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const getReportAutistaCamion = createAsyncThunk(
	"report_autisti/getReportAutistaCamion",
	async (consuntivoVivoId: number) => {
		return await reportAutistiCamionApi.getReportAutistaCamion(
			consuntivoVivoId
		);
	}
);

export const reportAutistiCamionSlice = createSlice({
	name: "reportAutistiCamionState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch dati per il report autisti (old-con schede duplicate)
		builder.addCase(fetchReportAutistiCamion.pending, (state, action) => {
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchReportAutistiCamion.fulfilled, (state, action) => {
			state.reportAutistiCamion = action.payload;
			state.reportAutistiCamion.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchReportAutistiCamion.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch dati per il report autisti tabella mensile
		builder.addCase(fetchReportAutisti.pending, (state, action) => {
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchReportAutisti.fulfilled, (state, action) => {
			state.reportAutisti = action.payload;
			state.reportAutistiCamion.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchReportAutisti.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch dati per il report autisti iolepec
		builder.addCase(fetchReportAutistiIolepec.pending, (state, action) => {
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchReportAutistiIolepec.fulfilled, (state, action) => {
			state.reportAutistiIolepec = action.payload.map(
				(autista: ReportAutistiIolepec) => {
					return {
						...autista,
						costo_totale: parseFloat(autista.costo_totale.toString()),
					};
				}
			);
			state.reportAutistiCamion.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchReportAutistiIolepec.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// fetch dati per il report autisti iolepec
		builder.addCase(
			fetchListaBIViaggiAutistiCamionMese.pending,
			(state, action) => {
				state.reportAutistiCamion.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchListaBIViaggiAutistiCamionMese.fulfilled,
			(state, action) => {
				state.listaBIViaggiAutistiCamionMese = action.payload;
				state.reportAutistiCamion.errorsStack = { status: ErrorStatusTypes.OK };
			}
		);
		builder.addCase(
			fetchListaBIViaggiAutistiCamionMese.rejected,
			(state, action) => {
				toast.error("Errore:" + action?.error?.message || "");
				state.reportAutistiCamion.errorsStack = {
					status: ErrorStatusTypes.ERROR,
					fieldsErrors: JSON.parse(action?.error?.message || ""),
				};
			}
		);
		// fetch dati per il report camion iolepec
		builder.addCase(fetchReportCamionsIolepec.pending, (state, action) => {
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchReportCamionsIolepec.fulfilled, (state, action) => {
			state.reportCamionsIolepec = action.payload.map(
				(camion: ReportCamionsIolepec) => {
					return {
						...camion,
						costo_totale: parseFloat(camion.costo_totale.toString()),
					};
				}
			);

			state.reportAutistiCamion.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchReportCamionsIolepec.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
		// get Report Autista
		builder.addCase(getReportAutistaCamion.pending, (state, action) => {
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getReportAutistaCamion.fulfilled, (state, action) => {
			state.reportAutistiCamion.results = state.reportAutistiCamion.results.map(
				(reportAutistaCamion) => {
					if (reportAutistaCamion.id == action.payload.id) {
						return action.payload;
					} else {
						return reportAutistaCamion;
					}
				}
			);
			state.reportAutistiCamion.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getReportAutistaCamion.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.reportAutistiCamion.errorsStack = {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: JSON.parse(action?.error?.message || ""),
			};
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = reportAutistiCamionSlice.actions;

export const reportAutistiCamionSliceReducer = reportAutistiCamionSlice.reducer;

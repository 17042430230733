import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

import {
	DocumentoDiTrasporto,
	DestinazioneTrasporto,
	VettoreTrasporto,
	getDocumentoDiTrasporto,
	mandaDdtEmail,
} from "../bolleFattureSlice";
import {
	Committente,
	TipoContattoTypes,
	Contatto,
} from "components/main/anagrafiche/anagraficheSlice";
import { UtenteLogged } from "../../../authentication/authenticationSlice";
import { PermissionTypes } from "../../../authentication/permissionsGroups";

// import ResaLavorazioniPDF from "./ResaLavorazioniPDF";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Stack from "@mui/material/Stack";
import { PDFViewer, pdf } from "@react-pdf/renderer";

import moment from "moment";
import SendIcon from "@mui/icons-material/Send";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import DocumentoDdt from "./DocumentoDdt";
import { Box } from "@mui/system";

const DdtStampaPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const { documentoDiTrasporto_id } = useParams();
	const documentoDiTrasportoId: number = parseInt(
		documentoDiTrasporto_id || ""
	);

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const documentoDiTrasporto = useSelector<
		RootState,
		DocumentoDiTrasporto | undefined
	>((state) =>
		state.bolleFattureState.documentiDiTrasporto.results.find(
			(ddt) => ddt.id == documentoDiTrasportoId
		)
	);
	const destinatario = useSelector<RootState, Committente | undefined>(
		(state) =>
			state.anagrafiche.committenti.results.find(
				(commitente) => commitente.id == documentoDiTrasporto?.destinatario
			)
	);
	const destinazione = useSelector<
		RootState,
		DestinazioneTrasporto | undefined
	>((state) =>
		state.bolleFattureState.destinazioniTrasporto.results.find(
			(destinazione) => destinazione.id == documentoDiTrasporto?.destinazione
		)
	);
	const vettore = useSelector<RootState, VettoreTrasporto | undefined>(
		(state) =>
			state.bolleFattureState.vettoriTrasporto.results.find(
				(vettore) => vettore.id == documentoDiTrasporto?.vettore
			)
	);

	useEffect(() => {
		dispatch(getDocumentoDiTrasporto(documentoDiTrasportoId));
	}, [getDocumentoDiTrasporto]);

	const [openContattiCommittente, setOpenContattiCommittente] = useState(false);
	const handleOpenContattiCommittente = () => {
		setOpenContattiCommittente(true);
	};
	const handleCloseContattiCommittente = () => {
		setOpenContattiCommittente(false);
	};

	const handlerMandaDdtEmail = async (contatto: Contatto) => {
		if (documentoDiTrasporto && destinatario) {
			const doc = (
				<DocumentoDdt
					documentoDiTrasporto={documentoDiTrasporto}
					destinatario={destinatario}
					destinazione={destinazione}
					vettore={vettore}
				/>
			);
			const blobPdf = await pdf(doc);
			blobPdf.updateContainer(doc);
			const result = await blobPdf.toBlob();
			var file = new File(
				[result],
				`Ddt_macellato_${moment(documentoDiTrasporto.data_documento).format(
					"DD_MM_yy"
				)}.pdf`
			);
			// indirizzoMail();

			documentoDiTrasporto?.id &&
				dispatch(
					mandaDdtEmail({
						ddt_id: documentoDiTrasporto.id,
						file: file,
						contatto: contatto,
					})
				);
		}
	};
	return (
		<Box>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{documentoDiTrasporto && destinatario && (
					<PDFViewer width={"100%"} height={"100%"}>
						<DocumentoDdt
							documentoDiTrasporto={documentoDiTrasporto}
							destinatario={destinatario}
							destinazione={destinazione}
							vettore={vettore}
						/>
					</PDFViewer>
				)}
			</Box>
			{(utente.is_staff ||
				(utente?.gruppi &&
					utente.gruppi.indexOf(PermissionTypes.PIANIFICATORI) > -1) ||
				(utente?.gruppi &&
					utente.gruppi.indexOf(PermissionTypes.OPERATORI_LOGISTICA) > -1)) && (
				<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
					<Button
						variant="contained"
						component={Link}
						to={{
							pathname: "/documento_di_trasporto",
						}}
						state={{
							documentoDiTrasporto_id: documentoDiTrasporto?.id,
						}}
						endIcon={<KeyboardReturnIcon />}
					>
						Torna al DDT
					</Button>
					<Button
						variant="contained"
						onClick={handleOpenContattiCommittente}
						endIcon={<SendIcon />}
					>
						Contatti Committente
					</Button>
				</Stack>
			)}

			<Dialog
				open={openContattiCommittente}
				onClose={handleCloseContattiCommittente}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Contatti di " + destinatario?.nome}
				</DialogTitle>
				<DialogContent>
					<List sx={{ pt: 0 }}>
						{destinatario?.contatti.map((contatto) => (
							<ListItem key={contatto.id}>
								<ListItemAvatar>
									<Avatar>
										{contatto.tipo_contatto == TipoContattoTypes.M && (
											<AlternateEmailIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.T && (
											<PhoneIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.C && (
											<PhoneIphoneIcon />
										)}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={contatto.riferimento}
									secondary={contatto.valore}
								/>
								{contatto.tipo_contatto == TipoContattoTypes.M && (
									<ListItemAvatar>
										<Button
											variant="contained"
											onClick={() => handlerMandaDdtEmail(contatto)}
											// onClick={() => {
											// 	handlermandaM4(contatto.id);
											// }}
											// disabled={!(lotto.stato_lotto.stato == 3)}
											sx={{ ml: 2 }}
											// color={lotto.m4_inviato ? "secondary" : "primary"}
										>
											{/* {lotto.m4_inviato ? "Reinvia M4" : "invia M4"} */}
											invia DDT
											<AlternateEmailIcon />
										</Button>
									</ListItemAvatar>
								)}
							</ListItem>
						))}
					</List>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default DdtStampaPage;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { Link } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import {
	Resa,
	fetchLavorazioniLotto,
	getResa,
	mandaResaEmail,
} from "../reseSlice";

import { avvertiUtenteDocumentiLotto } from "../../../allegati/allegatiSlice";

import {
	Committente,
	TipoContattoTypes,
	Contatto,
	Produttore,
} from "components/main/anagrafiche/anagraficheSlice";
import ResaPdf from "./ResaPdf";
import ResaLavorazioniPDF from "./ResaLavorazioniPDF";
import { UtenteLogged } from "../../../../authentication/authenticationSlice";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import { Lavorazione } from "../../../lavorazioni/lavorazioniSlice";
import SendIcon from "@mui/icons-material/Send";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { PDFViewer, Page, Document, pdf } from "@react-pdf/renderer";
import ComputerIcon from "@mui/icons-material/Computer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import moment from "moment";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

const indirizzoMail = (committente?: Committente) => {
	const indirizzoMail = committente?.contatti.find(
		(contatto) => contatto.tipo_contatto == "M"
	)?.valore;
	return indirizzoMail;
};

interface DocumentoResaProps {
	resa?: Resa;
	lavorazioni?: Lavorazione[];
}

const DocumentoResa = ({ resa, lavorazioni }: DocumentoResaProps) => {
	return (
		<Document>
			<Page
				size="A4"
				orientation="landscape"
				style={{ backgroundColor: "#fff", paddingTop: 20 }}
				key={"resa_" + resa?.id}
			>
				{resa && <ResaPdf resa={resa} />}
				{lavorazioni && <ResaLavorazioniPDF lavorazioni={lavorazioni} />}
			</Page>
		</Document>
	);
};

const DocumentoResaRidotto = ({ resa }: DocumentoResaProps) => {
	return (
		<Document>
			<Page
				size="A4"
				orientation="landscape"
				style={{ backgroundColor: "#fff", paddingTop: 20 }}
				key={"resa_" + resa?.id}
			>
				{resa && <ResaPdf resa={resa} />}
				{<ResaLavorazioniPDF lavorazioni={[]} />}
			</Page>
		</Document>
	);
};

const ResaStampaPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const { resa_id } = useParams();
	const resaId: number = parseInt(resa_id || "");

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const resa = useSelector<RootState, Resa | undefined>((state) =>
		state.reseState.rese.results.find((resa) => resa.id == resaId)
	);
	const lavorazioni = useSelector<RootState, Lavorazione[] | undefined>(
		(state) => state.reseState.lavorazioniLotto.results
	);
	const committente = useSelector<RootState, Committente | undefined>((state) =>
		state.anagrafiche.committenti.results.find(
			(commitente) => commitente.id == resa?.committente
		)
	);
	const produttore = useSelector<RootState, Produttore | undefined>((state) =>
		state.anagrafiche.produttori.results.find(
			(produttore) => produttore.id == resa?.produttore
		)
	);

	const [openContattiCommittente, setOpenContattiCommittente] = useState(false);
	const handleOpenContattiCommittente = () => {
		setOpenContattiCommittente(true);
	};
	const handleCloseContattiCommittente = () => {
		setOpenContattiCommittente(false);
	};

	const [openContattiProduttore, setOpenContattiProduttore] = useState(false);
	const handleOpenContattiProduttore = () => {
		setOpenContattiProduttore(true);
	};
	const handleCloseContattiProduttore = () => {
		setOpenContattiProduttore(false);
	};

	useEffect(() => {
		dispatch(getResa(resaId));
		dispatch(fetchLavorazioniLotto(resaId));
	}, [resaId]);

	const handlerMandaResaEmail = async (contatto: Contatto) => {
		const doc = <DocumentoResa resa={resa} lavorazioni={lavorazioni} />;
		const blobPdf = await pdf(doc);
		blobPdf.updateContainer(doc);
		const result = await blobPdf.toBlob();
		var file = new File([result], "Resa.pdf");
		indirizzoMail();

		resa?.id &&
			dispatch(
				mandaResaEmail({
					resa_id: resa.id,
					file: file,
					contatto: contatto,
				})
			);
	};

	const handlerMandaResaEmailRidotta = async (contatto: Contatto) => {
		const doc = <DocumentoResaRidotto resa={resa} />;
		const blobPdf = await pdf(doc);
		blobPdf.updateContainer(doc);
		const result = await blobPdf.toBlob();
		var file = new File([result], "Resa.pdf");
		indirizzoMail();

		resa?.id &&
			dispatch(
				mandaResaEmail({
					resa_id: resa.id,
					file: file,
					contatto: contatto,
				})
			);
	};
	const handlerAvvertiUtenteDocumentiLotto = async (
		utenteEsterno_id: number
	) => {
		resa?.id &&
			dispatch(
				avvertiUtenteDocumentiLotto({
					lotto_id: resa.id,
					utenteEsterno_id: utenteEsterno_id,
				})
			);
	};

	return (
		<Box>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{resa && (
					<PDFViewer width={"100%"} height={"100%"}>
						<DocumentoResa resa={resa} lavorazioni={lavorazioni} />
					</PDFViewer>
				)}
			</Box>
			{(utente.is_staff ||
				(utente?.gruppi &&
					utente.gruppi.indexOf(PermissionTypes.PIANIFICATORI) > -1) ||
				(utente?.gruppi &&
					utente.gruppi.indexOf(PermissionTypes.OPERATORI_LOGISTICA) > -1)) && (
				<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
					<Button
						variant="contained"
						onClick={handleOpenContattiCommittente}
						endIcon={<SendIcon />}
					>
						Contatti Committente
					</Button>
					<Button
						variant="contained"
						onClick={handleOpenContattiProduttore}
						endIcon={<SendIcon />}
					>
						Contatti Produttore
					</Button>
					<Button
						variant="contained"
						component={Link}
						to={{
							pathname: Routes_path.PIANIFICAZIONE,
						}}
						endIcon={<KeyboardReturnIcon />}
					>
						Torna alla pianificazione
					</Button>
				</Stack>
			)}
			<Dialog
				open={openContattiCommittente}
				onClose={handleCloseContattiCommittente}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Contatti di " + committente?.nome}
				</DialogTitle>
				<DialogContent>
					<List sx={{ pt: 0 }}>
						{committente?.utenti_esterni.map((utenteEsterno) => (
							<ListItem key={"utenteEsterno_" + utenteEsterno.id}>
								<ListItemAvatar>
									<Avatar sx={{ backgroundColor: "#32CD32" }}>
										<AccountCircleIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={
										utenteEsterno.user?.first_name +
										" " +
										utenteEsterno.user?.last_name
									}
									secondary="utente applicativo"
								/>
								<ListItemAvatar>
									<Button
										variant="contained"
										color="info"
										onClick={() =>
											handlerAvvertiUtenteDocumentiLotto(utenteEsterno.id || 0)
										}
										sx={{ ml: 2 }}
									>
										avverti documenti disponibili
										<ComputerIcon sx={{ ml: 1 }} />
									</Button>
								</ListItemAvatar>
							</ListItem>
						))}
						{committente?.contatti.map((contatto) => (
							<ListItem key={"contatto_" + contatto.id}>
								<ListItemAvatar>
									<Avatar>
										{contatto.tipo_contatto == TipoContattoTypes.M && (
											<AlternateEmailIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.T && (
											<PhoneIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.C && (
											<PhoneIphoneIcon />
										)}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={contatto.riferimento}
									secondary={contatto.valore}
								/>
								{contatto.tipo_contatto == TipoContattoTypes.M && (
									<ListItemAvatar>
										<Button
											variant="contained"
											onClick={() => handlerMandaResaEmail(contatto)}
											// onClick={() => {
											// 	handlermandaM4(contatto.id);
											// }}
											// disabled={!(lotto.stato_lotto.stato == 3)}
											sx={{ ml: 2 }}
											// color={lotto.m4_inviato ? "secondary" : "primary"}
										>
											{/* {lotto.m4_inviato ? "Reinvia M4" : "invia M4"} */}
											invia resa
											<AlternateEmailIcon />
										</Button>
									</ListItemAvatar>
								)}
							</ListItem>
						))}
					</List>
				</DialogContent>
			</Dialog>
			<Dialog
				open={openContattiProduttore}
				onClose={handleCloseContattiProduttore}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Contatti di " + produttore?.nome}
				</DialogTitle>
				<DialogContent>
					<List sx={{ pt: 0 }}>
						{produttore?.contatti.map((contatto) => (
							<ListItem key={contatto.id}>
								<ListItemAvatar>
									<Avatar>
										{contatto.tipo_contatto == TipoContattoTypes.M && (
											<AlternateEmailIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.T && (
											<PhoneIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.C && (
											<PhoneIphoneIcon />
										)}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={contatto.riferimento}
									secondary={contatto.valore}
								/>
								{contatto.tipo_contatto == TipoContattoTypes.M && (
									<ListItemAvatar>
										<Button
											variant="contained"
											onClick={() => handlerMandaResaEmailRidotta(contatto)}
											sx={{ ml: 2 }}
										>
											invia resa ridotta
											<AlternateEmailIcon />
										</Button>
									</ListItemAvatar>
								)}
							</ListItem>
						))}
					</List>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default ResaStampaPage;

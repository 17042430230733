import React, { useState, useEffect } from "react";

import { Parametri } from "../../anagrafiche/anagraficheSlice";
import { ConsuntivoVivo } from "./consuntiviVivoSlice";
import ConsuntivoVivoForm from "./ConsuntivoVivoForm";

import moment from "moment";
import { theme } from "../../../../theme";
import AlleggatiPage from "../../allegati/AlleggatiPage";
import { styled } from "@mui/system";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import Accordion from "@mui/material/Accordion";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PeopleIcon from "@mui/icons-material/People";
import TimerIcon from "@mui/icons-material/Timer";
import Chip from "@mui/material/Chip";
// import Chip from "@material-ui/core/Chip"; ************************ da cancellare se non ci sono malfunzionamenti
import Grid from "@mui/material/Grid";
// import { Link } from "react-router-dom";
import Link from "@mui/material/Link";

import AttachFileIcon from "@mui/icons-material/AttachFile";

const NumberChip = styled(Chip)({
	"&&": {
		height: "1.5rem",
		backgroundColor: "#1b75b1",
		color: "#e1e1e1",
		fontSize: "1.2rem",
		fontWeight: 500,
		paddingLeft: 0,
		paddingRight: 0,
		borderRadius: 5,
	},
	"& .MuiChip-label": {
		paddingLeft: "5px",
		paddingRight: "5px",
	},
});
interface ConsuntivoVivoCardProps {
	consuntivoVivo: ConsuntivoVivo;
	saveConsuntivoVivo: (consuntivoVivo: ConsuntivoVivo) => void;
	parametri: Parametri;
	expanded: string | false;
	handleChangeConsuntivoVivoPanel: (
		consuntivoVivo: ConsuntivoVivo
	) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}
const ConsuntivoVivoCard = ({
	consuntivoVivo,
	saveConsuntivoVivo,
	parametri,
	expanded,
	handleChangeConsuntivoVivoPanel,
}: ConsuntivoVivoCardProps) => {
	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 400,
		width: "95%",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		pb: 1,
		textAlign: "center",
	};

	return (
		<Accordion
			key={"consuntivoVivo_panel" + consuntivoVivo.id}
			sx={{
				border: "4px",
				borderStyle: "solid",
				borderColor:
					consuntivoVivo.stato_consuntivo_vivo?.stato == 3
						? "red"
						: consuntivoVivo.stato_consuntivo_vivo?.stato == 2
						? "lightgreen"
						: consuntivoVivo.stato_consuntivo_vivo?.stato == 1
						? "rgba(91, 188, 247, 1)"
						: "background.paper",
				boxShadow:
					" 0px 0px 6px 5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
			}}
			expanded={expanded === "consuntivoVivo_" + consuntivoVivo.id}
			onChange={handleChangeConsuntivoVivoPanel(consuntivoVivo)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="consuntivo_giornata_accordion"
				id="consuntivo_giornata_accordion_header"
			>
				<Grid container spacing={{ xs: 1, md: 2 }}>
					<Grid item sm={1} md={1} lg={1}>
						<NumberChip
							aria-label="consuntivo_giornata"
							label={consuntivoVivo.id}
						/>
					</Grid>
					<Grid item sm={11} md={11} lg={11}>
						<Stack direction="row" spacing={2} justifyContent="flex-start">
							<Chip
								variant="outlined"
								icon={<LocalShippingIcon />}
								// color="success"
								// color="primary"
								label={
									consuntivoVivo.camion_pack?.nome +
									" " +
									consuntivoVivo.camion_pack?.targa
								}
								style={{
									// color: theme.palette.primary.main,
									// backgroundColor: theme.palette.primary.main,
									fontWeight: 500,
								}}
							/>
							<Chip
								icon={<PeopleIcon />}
								variant="outlined"
								// color={
								// 	consuntivoVivo.autisti_pack.length > 0 ? "primary" : "default"
								// }
								label={consuntivoVivo.autisti_pack
									.map((autista) => autista.cognome)
									.join(",")}
								style={{
									// color: theme.palette.primary.main,
									// backgroundColor: theme.palette.primary.main,
									fontWeight: 500,
								}}
							/>
							<Chip
								variant="outlined"
								icon={<TimerIcon />}
								// color={
								// 	consuntivoVivo.data_ora_carico_pianificata
								// 		? "primary"
								// 		: "default"
								// }
								label={
									consuntivoVivo.data_ora_carico_pianificata &&
									"carico prev. " +
										moment(consuntivoVivo.data_ora_carico_pianificata).format(
											"D MMM HH:mm"
										)
								}
								style={{
									// color: theme.palette.primary.main,
									// borderColor: theme.palette.primary.main,
									fontWeight: 500,
								}}
							/>

							<Chip
								icon={<AttachFileIcon />}
								variant="outlined"
								color={
									consuntivoVivo.allegati.length > 0 ? "primary" : "default"
								}
								label={consuntivoVivo.allegati
									.map((allegato) => allegato.tipo_documento_des?.nome)
									.join(",")}
							/>
							{consuntivoVivo &&
								consuntivoVivo.stato_consuntivo_vivo?.stato == 3 && (
									<Box>
										<Tooltip
											title={consuntivoVivo.stato_consuntivo_vivo.messaggio}
											placement="left-start"
											disableFocusListener
											enterTouchDelay={10}
											arrow
										>
											<ContactSupportIcon
												sx={{
													color: "red",
													borderColor: "white",
													mr: "-0.125rem",
													flexGrow: 1,
												}}
											/>
										</Tooltip>
									</Box>
								)}
						</Stack>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					py: 0,
					"&:last-child": { pb: 1 },
				}}
			>
				{expanded === "consuntivoVivo_" + consuntivoVivo.id && (
					<ConsuntivoVivoForm
						consuntivoVivo={consuntivoVivo}
						saveConsuntivoVivo={saveConsuntivoVivo}
						parametri={parametri}
					/>
				)}
			</AccordionDetails>
			<AccordionDetails
				sx={{
					py: 0,
					"&:last-child": { pb: 1 },
				}}
			>
				{expanded === "consuntivoVivo_" + consuntivoVivo.id && (
					<AlleggatiPage schedaCarico_id={consuntivoVivo.id} />
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default ConsuntivoVivoCard;
